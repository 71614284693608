/* eslint-disable-next no-shadow */
export const CHECKOUT_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a';

export enum FedopsInteractions {
  ApplyCouponInteraction = 'apply-coupon-interaction',
  ApplyGiftCardInteraction = 'apply-gift-card-interaction',
  PlaceOrderInteraction = 'place-order-interaction',
  ValidatePaymentInteraction = 'validate-payment-interaction',
  InitializePaymentInPlaceOrderInteraction = 'initialize-payment-in-place-order-interaction',
  ChangeDeliveryMethodInteraction = 'change-delivery-method-interaction',
  SubmitCustomerDetailsInteraction = 'submit-customer-details-interaction',
  InitFormsInteraction = 'init-forms-interaction',
  FetchCheckout = 'fetch-checkout',
  SetBillingAndPaymentInteraction = 'set-billing-and-payment-interaction',
}

export const SPECS = {
  AddSlotToCheckout: 'specs.stores.AddSlotToCheckout',
  AddTitleToDeliveryDetails: 'specs.stores.AddTitleToDeliveryDetails',
  SupportUpdateCheckoutOnSlotRefreshCheckout: 'specs.stores.SupportUpdateCheckoutOnSlotRefreshCheckout',
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  ShowLoaderWhileLoadingNewCheckout: 'specs.stores.ShowLoaderWhileLoadingNewCheckout',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  ShouldSupportTimeSlotsInCheckout: 'specs.stores.ShouldSupportTimeSlotsInCheckout',
  Fix3DSInExpressMethodsFastFlow: 'specs.stores.Fix3DSInExpressMethodsFastFlow',
  MergePaymentAndPlaceOrderSteps: 'specs.stores.MergePaymentAndPlaceOrderSteps',
  ShouldFetchCustomSettingsAndCustomizeCheckoutLayout:
    'specs.stores.ShouldFetchCustomSettingsAndCustomizeCheckoutLayout',
  ShouldUsePanorama: 'specs.stores.ShouldUsePanorama',
  SupportGrooLogic: 'specs.stores.SupportGrooLogic',
  UseOrderExtendedFields: 'specs.stores.UseOrderExtendedFields',
  HideTaxWhenZeroForGroo: 'specs.stores.HideTaxWhenZeroForGroo',
  FilterCountriesByShippingCountries: 'specs.stores.FilterCountriesByShippingCountries',
  GetCouponDiscountRowFromPriceSummary: 'specs.stores.GetCouponDiscountRowFromPriceSummary',
  ShouldSplitBillingInfoPrefill: 'specs.stores.ShouldSplitBillingInfoPrefill',
  SupportNewMobileSummaryBehavior: 'specs.stores.SupportNewMobileSummaryBehavior',
  CheckoutSummaryItemErrorDivider: 'specs.stores.CheckoutSummaryItemErrorDivider',
  SlotSpacingAndDividers: 'specs.stores.SlotSpacingAndDividers',
  ShowLineItemDiscountPriceByQuantity: 'specs.stores.ShowLineItemDiscountPriceByQuantity',
  SupportSuccessUrlParamInCheckout: 'specs.stores.SupportSuccessUrlParamInCheckout',
  SupportDonationsKeys: 'specs.stores.SupportDonationsKeys',
  UseDynamicStepForDeliveryMethod: 'specs.stores.UseDynamicStepForDeliveryMethod',
  UseDeliveryMethodStepSlot: 'specs.stores.UseDeliveryMethodStepSlot',
  MoveAdditionalInfoFormToTheBottom: 'specs.stores.MoveAdditionalInfoFormToTheBottom',
  UseSingleBillingForm: 'specs.stores.UseSingleBillingForm',
};

export enum FieldMasks {
  customField = 'customFields',
  extendedFields = 'extendedFields',
  buyerInfoEmail = 'buyerInfo.email',
  shippingContact = 'shippingInfo.shippingDestination.contactDetails',
  shippingAddress = 'shippingInfo.shippingDestination.address',
  shippingAddressesServiceId = 'shippingInfo.shippingDestination.addressesServiceId',
  billingContact = 'billingInfo.contactDetails',
  billingAddress = 'billingInfo.address',
  billingAddressesServiceId = 'billingInfo.addressesServiceId',
  selectedCarrierServiceOption = 'shippingInfo.selectedCarrierServiceOption',
}

export enum AddressesFieldMasks {
  firstName = 'fullName.firstName',
  lastName = 'fullName.lastName',
  company = 'company',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  country = 'country',
  subdivision = 'subdivision',
  zipCode = 'zipCode',
  phoneNumber = 'phoneNumber',
  setAsDefault = 'setAsDefault',
  taxId = 'taxInfo.id',
  taxType = 'taxInfo.type',
  streetName = 'street.name',
  streetNumber = 'street.number',
}

export const FIELDS_GAP = 20;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const THUMBNAIL_WIDTH = 60;

export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const DELIVERY_METHOD_GROUP_NAME = 'deliveryOptions';

export const MAX_CUSTOM_FIELD_VALUE_LENGTH = 250;
export const MAX_ZIP_CODE_FIELD_LENGTH = 250;

export const ONE_COLUMN_VIEW_WIDTH_THRESHOLD = 750;

export const PAYPAL = 'PayPal';

export const FORMS_TEMPLATE_IDS = {
  EMAIL: 'b739c372-940c-4148-8d13-9f139a662fa2',
  CONTACT: '5c1d74e6-ccaa-4e2f-9f9f-fb620ef73446',
  ADDITIONAL_INFO: 'afb63805-2e62-495e-969c-e45191af3818',
  ADDRESS: 'd5993645-4531-4705-9172-186be64aa429',
  VAT_ID: '90553971-6055-4a40-a746-75c8996dfe36',
  BILLING: '40f8c94a-07d8-453b-b470-d26508a8da97',
};

export const FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER = {
  EMAIL: '6bddf2de-2577-45c9-8a35-71c4f4695210',
  CONTACT: '73260314-14b4-4047-ad8c-b1998b2fa8e1',
  ADDRESS: '7fad480d-1dcd-4c95-a4af-714a741dd5a7',
  EXTENDED_FIELDS_FORM_ID: '391eeac7-0183-4955-8663-99bb01ab0ab4',
};

export const FORMS_CHECKOUT_NAMESPACE = 'wix.ecom.v1.checkout';

export const DEFAULT_COUNTRY = 'US';
export const GROO_DEFAULT_COUNTRY = 'IL';

export const COUNTRIES_WITH_VAT = ['BR'];

export const ADD_NEW_ADDRESS_ID = 'addNewAddress';

export enum THEMES {
  DONATIONS = 'donations',
}
